import { yupResolver } from "@hookform/resolvers/yup";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup";
import imgnoti from "../../../../../assets/images/icons/img-noti.svg";

import { ROLE } from "constants";

import classNames from "classnames";
import ButtonSystem from "components/e-control/button-system";
import SelectSystem from "components/e-control/forms/form-elements/select-system";
import UploadImageSystem from "components/e-control/forms/form-elements/upload-image-system";
import ModalSystem from "components/e-control/modal-system";
import { getAllCouponForAdmin } from "modules/ego-system/coupon/stores";
import { getAllWorkPlace } from "modules/ego-system/location/stores";
import { getAllUser } from "modules/ego-system/user/stores";
import { Link } from "react-router-dom";
import { dispatch } from "root-stores";
import { NOTIFICATION_ROUTER } from "router/ego-admin-routes/notification";
import "./index.scss";
import Editor from "components/e-control/editor";

const optionList = [
  {
    id: "SEND_ALL_USER",
    name: "Gửi tất cả người dùng",
  },
  {
    id: "SEND_ONE_USER",
    name: "Gửi 1 người dùng",
  },
  {
    id: "SEND_BY_WORKPLACE",
    name: "Gửi bởi nơi làm việc",
  },
];

const typeList = [
  {
    id: "VOUCHER",
    name: "Khuyễn mãi",
  },
];
const FormNotification = ({
  loading,
  toggleSideBar,
  addNotification,
  setDataCkeditor,
  dataCkeditor,
}) => {
  const [optionValue, setOptionValue] = useState("");
  const editorRef = useRef(null);

  const createSchema = useMemo(() => {
    const schema = {
      type: yup.string().required("Vui lòng chọn loại!"),
      title: yup.string().required("Vui lòng nhập tiêu đề!"),
      body: yup.string().required("Vui lòng nhập nội dung chi tiết!"),
      option: yup.string().required("Vui lòng chọn loại đối tượng!"),
      images: yup.string().required("Vui lòng nhập hình ảnh!"),
      // content: yup.string().required("Vui lòng nhập nội dung!"),
      // couponId: yup.string().required("Vui lòng chọn mã khuyến mãi!"),
      // workPlaceId: yup.string().required("Vui lòng chọn nơi làm việc!"),
      // userId: yup.string().required("Vui lòng chọn khách hàng!"),
    };

    if (optionValue === "SEND_ALL_USER") {
      delete schema.workPlaceId;
      delete schema.userId;
    }

    if (optionValue === "SEND_ONE_USER") {
      delete schema.workPlaceId;
    }

    if (optionValue === "SEND_BY_WORKPLACE") {
      delete schema.userId;
    }

    return yup.object().shape(schema);
  }, [optionValue]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    register,
    setError,
  } = useForm({
    resolver: yupResolver(createSchema),
    defaultValues: {},
  });

  const { workplaces } = useSelector((state) => state.location);
  const { userData } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.user);
  const { coupons } = useSelector((state) => state.coupon);
  const [modalNotification, setModalNotification] = useState(false);

  const { images } = watch();

  const loadListUser = async () => {
    await dispatch(
      getAllUser({
        page: 1,
        limit: 1000,
        filter: { role: "CLIENT" },
      })
    );
  };

  const loadListCoupons = async () => {
    await dispatch(getAllCouponForAdmin());
  };

  const loadListWP = async () => {
    await dispatch(getAllWorkPlace());
  };

  useEffect(() => {
    loadListUser();
    loadListWP();
    loadListCoupons();
  }, []);

  const sendFor = watch("option");

  const onSubmit = (data) => {
    const customData = {};

    const filterData = Object.keys(data)?.filter((item, index) => {
      return data[item];
    });

    Object.keys(data).forEach(function (key) {
      if (filterData?.includes(key)) {
        customData[key] = data[key];
      }
    });

    addNotification?.({ ...customData });
  };

  // const handleChange = (e, editor) => {
  //   setContentCkData(editor.getData());
  // };

  // const handleUploaded = (imgList) => {
  //   if (imgList?.length > 0) {
  //     setValue("images", imgList[0]?.url || imgList[0]);
  //   } else {
  //     setValue("images", "");
  //   }
  // };

  useEffect(() => {
    if (sendFor) {
      setOptionValue(sendFor);
    }
  }, [sendFor]);

  const handleGetValue = () => {
    const formData = getValues();
    console.log(formData);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="noti-form">
      <>
        <Row lg={2}>
          {userData?.role === ROLE.ADMIN && (
            <FormGroup className="noti-form__group">
              <Label className="noti-form__label">Loại đối tượng</Label>
              <Controller
                name="option"
                control={control}
                render={({ field }) => (
                  <SelectSystem
                    {...field}
                    valueKey="id"
                    labelKey="name"
                    data={optionList}
                    classNamePrefix="select"
                    placeholder="Chọn loại đối tượng"
                    className={classNames({
                      "is-invalid": errors.option && true,
                    })}
                  />
                )}
              />

              <FormFeedback>{errors.option?.message}</FormFeedback>
            </FormGroup>
          )}

          <FormGroup className="noti-form__group">
            <Label className="noti-form__label">Loại</Label>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <SelectSystem
                  {...field}
                  valueKey="id"
                  labelKey="name"
                  data={typeList}
                  classNamePrefix="select"
                  placeholder="Chọn loại"
                  className={classNames({
                    "is-invalid": errors.type && true,
                  })}
                />
              )}
            />

            <FormFeedback>{errors.type?.message}</FormFeedback>
          </FormGroup>

          {sendFor === "SEND_BY_WORKPLACE" ? (
            <FormGroup className="noti-form__group">
              <Label className="noti-form__label">Nơi làm việc</Label>
              <Controller
                name="workPlaceId"
                control={control}
                render={({ field }) => (
                  <SelectSystem
                    {...field}
                    valueKey="id"
                    labelKey="name"
                    data={workplaces?.list}
                    classNamePrefix="select"
                    placeholder="Chọn nơi làm việc"
                    className={classNames({
                      "is-invalid": errors.workPlaceId && true,
                    })}
                  />
                )}
              />
              {(errors.workPlaceId?.type === "optionality" ||
                errors.workPlaceId?.type === "required") && (
                <FormFeedback>{errors.workPlaceId?.message}</FormFeedback>
              )}
            </FormGroup>
          ) : (
            ""
          )}

          {sendFor === "SEND_ONE_USER" ? (
            <FormGroup className="noti-form__group">
              <Label className="noti-form__label">Khách hàng</Label>
              <Controller
                name="userId"
                control={control}
                render={({ field }) => (
                  <SelectSystem
                    {...field}
                    valueKey="id"
                    labelKey="name"
                    data={users?.list}
                    classNamePrefix="select"
                    placeholder="Chọn khách hàng"
                    className={classNames({
                      "is-invalid": errors.userId && true,
                    })}
                  />
                )}
              />
              {(errors.userId?.type === "optionality" ||
                errors.userId?.type === "required") && (
                <FormFeedback>{errors.userId?.message}</FormFeedback>
              )}
            </FormGroup>
          ) : (
            ""
          )}

          <FormGroup className="noti-form__group">
            <Label className="noti-form__label">Khuyến mãi</Label>
            <Controller
              name="couponId"
              control={control}
              render={({ field }) => (
                <SelectSystem
                  {...field}
                  valueKey="id"
                  labelKey="code"
                  data={coupons?.list}
                  classNamePrefix="select"
                  placeholder="Chọn coupon"
                  className={classNames({
                    "is-invalid": errors.couponId && true,
                  })}
                />
              )}
            />

            <FormFeedback>{errors.couponId?.message}</FormFeedback>
          </FormGroup>

          <FormGroup className="noti-form__group">
            <Label className="noti-form__label">Tiêu đề</Label>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputSystem
                  {...field}
                  placeholder="Nhập tiêu đề"
                  className={classNames("form-control", {
                    "is-invalid": errors.title && true,
                  })}
                  style={{ fontSize: "12px" }}
                />
              )}
            />

            <FormFeedback>{errors.title?.message}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label className="noti-form__label">Hình ảnh</Label>
            <Controller
              name="images"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UploadImageSystem maxSizeMB={0.1} {...field} />
              )}
            />

            <div className="error-red">{errors.images?.message}</div>
          </FormGroup>

          <FormGroup className="noti-form__group">
            <Label className="noti-form__label">Chi tiết tiêu đề</Label>
            <Controller
              name="body"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputSystem
                  {...field}
                  rows="3"
                  type="textarea"
                  placeholder="Nhập chi tiết tiêu đề"
                  className={classNames("form-control", {
                    "is-invalid": errors.body && true,
                  })}
                  style={{ fontSize: "12px" }}
                />
              )}
            />

            <FormFeedback>{errors.body?.message}</FormFeedback>
          </FormGroup>
        </Row>

        <FormGroup>
          <Label className="partner-form__label">Nội dung</Label>
          <Editor
            ref={editorRef}
            value={dataCkeditor}
            onChange={(html) => {
              setDataCkeditor(html);
            }}
          />
        </FormGroup>

        <div className="noti-form__button-group">
          <div>
            <ButtonSystem
              type="button"
              color="danger"
              onClick={toggleSideBar}
              className="noti-form__button-cancel"
            >
              <Link
                to={`${NOTIFICATION_ROUTER.NOTICE}`}
                className="back-noti-form"
              >
                Trở về
              </Link>
            </ButtonSystem>
          </div>

          <div>
            <ButtonSystem
              loading={loading}
              color="success"
              type="submit"
              className="noti-form__button-submit"
            >
              Thêm
            </ButtonSystem>
          </div>

          <div>
            <ButtonSystem
              color="success"
              onClick={() => {
                handleGetValue();
                setModalNotification(true);
              }}
              className="noti-form__button-submit"
            >
              Xem trước
            </ButtonSystem>
          </div>
        </div>
      </>

      <ModalSystem
        centered
        isOpen={modalNotification}
        header={"Chi tiết thông báo"}
        size="sm"
        onClose={() => setModalNotification(false)}
        className="mx-auto w-full md:w-[500px]"
      >
        <div className="flex gap-4">
          <div className="flex-shrink-0">
            <img
              src={images || imgnoti}
              width={80}
              height={80}
              alt={"img"}
              className="object-contain"
            />
          </div>

          <div>
            <h3>
              <b>{getValues("title")}</b>
            </h3>
            <div>{getValues("body")}</div>
          </div>
        </div>
      </ModalSystem>
    </Form>
  );
};

export default FormNotification;

import ButtonSystem from "components/e-control/button-system";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import UploadImageSystem from "components/e-control/forms/form-elements/upload-image-system";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Label } from "reactstrap";

const EditorUploadForm = ({
    onClose,
    onSubmit,
}) => {
    const form = useForm({
        mode: 'onChange',
    })

    const { control, handleSubmit } = form;

    const onFormSubmit = (formData) => {
        onSubmit?.(formData?.url || '')
    }

    return (
        <Form
            control={control}
            onSubmit={handleSubmit(onFormSubmit)}
        >
            <FormGroup>
                <Label>
                    Link hình ảnh
                </Label>

                <Controller
                    name="url"
                    control={control}
                    render={({ field }) => (
                        <InputSystem 
                            value={field.value || ''}
                            onChange={(event) => field.onChange?.(event.target.value)}
                        />
                    )}
                />
            </FormGroup>

            <FormGroup>
                <Label className="">Tải ảnh lên</Label>
                <Controller
                    name="url"
                    control={control}
                    render={({ field }) => (
                        <UploadImageSystem maxSizeMB={0.1} {...field} />
                    )}
                />
            </FormGroup>

            <div className="flex items-center justify-end gap-x-4">
                <ButtonSystem
                    color="danger"
                    type="button"
                    onClick={onClose}
                >
                    Hủy
                </ButtonSystem>

                <ButtonSystem
                    color="primary"
                    type="button"
                    onClick={handleSubmit(onFormSubmit)}
                >
                    Lưu
                </ButtonSystem>
            </div>
        </Form>
    )
}

export default EditorUploadForm;
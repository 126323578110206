/* eslint-disable multiline-ternary */
import { ACCOUNT_BUSINESS_TYPE, LOCAL_STORAGE, ROLE } from "constants";
import toast from "react-hot-toast";
import { AUTH_ROUTES } from "router/ego-admin-routes/auth";
import { NOTIFICATION_ROUTER } from "router/ego-admin-routes/notification";
import { GOVERNMENT_ROUTES } from "router/government-routes";
import { INVEST_ROUTES } from "router/invest-routes";
import { PARTNER_ROUTES } from "router/partner-routes/routes-constants";
import { SUPPER_ADMIN } from "router/supper-admin-routes";
import LocalUtils from "./storage";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * @description format phone number
 */
export const formatPhoneNumberToShow = (phone, code = "84") => {
  const regex = new RegExp(`^\\+${code}`);
  return phone?.replace(regex, "0");
};

export const formatPhoneNumberToServer = (phone, code = "+84") => {
  return phone?.replace(/^0/, code);
};

export const formatPhoneNumberToServerLaos = (phone, code = "+856") => {
  return phone?.replace(/^0/, code);
};

// ** Returns K format from a number
// eslint-disable-next-line no-confusing-arrow
export const kFormatter = (num) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Generate key
export const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" },
  timeZone = "en-US"
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat(timeZone, formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 */
export const isUserLoggedIn = () => LocalUtils.get(LOCAL_STORAGE.USER_DATA);
export const getUserData = () => {
  try {
    const userData = JSON.parse(LocalUtils.get(LOCAL_STORAGE.USER_DATA));

    if (userData) {
      return userData;
    }

    return null;
  } catch (error) {
    return null;
  }
};

/**
 * The function is used for check user role permission
 * @param {String} userRole role of user
 * @param {Array<String>} permissionRoles
 */
export const checkRolePermissionRoutes = (userRole, permissionRoles = []) => {
  return permissionRoles.includes(userRole);
};

/**
 * @description function used for check business type for partner
 */
export const checkBusinessTypePermissionRoutes = (
  userInfo,
  routeMetaOptions
) => {
  if (userInfo.role !== ROLE.PARTNER) {
    return true;
  }
  const businessTypeKey = (() => {
    switch (userInfo.partnerTypes?.[0]) {
      case ACCOUNT_BUSINESS_TYPE.TRANSPORTATION.VALUE:
        return "transportationTypes";

      case ACCOUNT_BUSINESS_TYPE.SHOPPING.VALUE:
        return "shoppingTypes";

      case ACCOUNT_BUSINESS_TYPE.TRAVEL.VALUE:
        return "travelTypes";

      default:
        return "";
    }
  })();

  if (!businessTypeKey) {
    return false;
  }

  const types = userInfo?.[businessTypeKey] ?? [];

  const permissionTypes =
    routeMetaOptions?.permissionTypes?.[userInfo.partnerTypes?.[0]] ?? [];
  return types.some((type) => permissionTypes.includes(type));
};

export const getPermissionByBusinessType = () => {};

/**
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (user) => {
  if (user) {
    if (user.role === ROLE.SUPER_ADMIN) {
      return SUPPER_ADMIN.ADMIN_ACCOUNT;
    }

    if (user.role === ROLE.GOVERNMENT) {
      return GOVERNMENT_ROUTES.GOVERNMENT;
    }

    if (user.role === ROLE.INVEST_MANAGER) {
      return INVEST_ROUTES.ACCOUNT;
    }

    if (user.role === ROLE.PARTNER) {
      if (user.partnerTypes?.[0] === ACCOUNT_BUSINESS_TYPE.MINIPOST.VALUE) {
        return PARTNER_ROUTES.EPOS.DASHBOARD;
      }
    }

    if (user?.partner?.role === ROLE.PARTNER) {
      if (
        user?.partner?.partnerTypes?.[0] ===
        ACCOUNT_BUSINESS_TYPE.MINIPOST.VALUE
      ) {
        return PARTNER_ROUTES.EPOS.ORDER;
      }
    }
    if (user.role === ROLE.CLIENT) {
      return INVEST_ROUTES.INVEST_WEBVIEW;
    }

    return NOTIFICATION_ROUTER.NOTICE;
  }

  return AUTH_ROUTES.LOGIN;
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export function filterObject(obj) {
  let filteredObj = {};
  for (let key in obj) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== "" &&
      obj[key] !== undefined
    ) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
}

export const parseDataFromLocalStorage = (storageData) => {
  try {
    const data = JSON.parse(storageData);
    return data;
  } catch (error) {
    return null;
  }
};

export const copyContent = async (content) => {
  try {
    await navigator.clipboard.writeText(content);

    toast.success("Copy successfully");
    return true;
  } catch (error) {
    return false;
  }
};

export const hiddenMiddleCharacter = (address) => {
  if (address.length <= 8) {
    return address; // If the address is too short, return it as is
  }

  const start = address.slice(0, 4);
  const end = address.slice(-6);
  const hidden = `${start}...${end}`;

  return hidden;
};

import { gql } from "@apollo/client";

export const getAllMnpUser = gql`
  query getAllMnpUser($q: QueryGetListInput) {
    getAllMnpUser(q: $q) {
      data {
        address
        createdAt
        email
        id
        key
        name
        password
        phone
        role
        staffIds
        partnerId
        partner {
          id
          name
        }
      }
      total
    }
  }
`;

export const getAllMnpArea = gql`
  query getAllMnpArea($q: QueryGetListInput) {
    getAllMnpArea(q: $q) {
      data {
        createdAt
        id
        name
        updatedAt
      }
      total
    }
  }
`;

export const getAllMnpAreaWOPaging = gql`
  query getAllMnpAreaWOPaging {
    getAllMnpAreaWOPaging {
      createdAt
      id
      name
      mnpUser {
        id
        name
      }
      updatedAt
    }
  }
`;

export const getAllMnpTable = gql`
  query getAllMnpTable($q: QueryGetListInput) {
    getAllMnpTable(q: $q) {
      data {
        createdAt
        id
        mnpArea {
          id
          name
        }
        name
        status
        guestCount
        bookingTime
        updatedAt
      }
      total
    }
  }
`;

export const getAllMnpCategory = gql`
  query getAllMnpCategory($q: QueryGetListInput) {
    getAllMnpCategory(q: $q) {
      data {
        id
        createdAt
        name
        mnpUser {
          id
          name
        }
      }
      total
    }
  }
`;

export const getAllMnpCategoryWOPaging = gql`
  query getAllMnpCategoryWOPaging {
    getAllMnpCategoryWOPaging {
      id
      createdAt
      name
      mnpUser {
        id
        name
      }
    }
  }
`;

export const getAllBankWOPaging = gql`
  query getAllBankWOPaging {
    getAllBankWOPaging {
      bankId
      bin
      createdAt
      id
      name
      logo
      key
      shortName
      type
    }
  }
`;

export const getAllMnpProduct = gql`
  query getAllMnpProduct($q: QueryGetListInput) {
    getAllMnpProduct(q: $q) {
      data {
        id
        createdAt
        image
        mnpCategoryId
        mnpCategory {
          id
          name
        }
        name
        price
      }
      total
    }
  }
`;

export const getAllMnpOrder = gql`
  query getAllMnpOrder($q: QueryGetListInput) {
    getAllMnpOrder(q: $q) {
      data {
        id
        count
        mnpTableId
        name
        createdAt
        mnpProduct {
          id
          name
          price
        }
        mnpTable {
          id
          name
          mnpArea {
            id
            name
          }
        }
      }
      total
    }
  }
`;

export const getAllMnpOrderForAdmin = gql`
  query getAllMnpOrderForAdmin($q: QueryGetListInput) {
    getAllMnpOrderForAdmin(q: $q) {
      data {
        count
        createdAt
        id
        mnpProduct {
          id
          image
          name
          price
        }
        mnpTable {
          id
          name
          bookingTime
          status
          guestCount
        }
        name
      }
      total
    }
  }
`;

export const getAllMnpPaymentAccount = gql`
  query getAllMnpPaymentAccount($q: QueryGetListInput) {
    getAllMnpPaymentAccount(q: $q) {
      data {
        bank {
          id
          name
          bankId
          logo
        }
        bankId
        bankName
        bankNo
        createdAt
        id
        name
        qrCode(amount: 0)
      }
      total
    }
  }
`;

export const getOneMnpPaymentAccount = gql`
  query getOneMnpPaymentAccount($id: ID!, $amount: Int) {
    getOneMnpPaymentAccount(id: $id) {
      bank {
        bankId
        id
        name
        logo
      }
      bankId
      bankName
      bankNo
      createdAt
      id
      name
      qrCode(amount: $amount)
    }
  }
`;

export const createMnpUser = gql`
  mutation createMnpUser($data: CreateMnpUserInput!) {
    createMnpUser(data: $data) {
      id
    }
  }
`;

export const createMnpArea = gql`
  mutation createMnpArea($data: CreateMnpAreaInput!) {
    createMnpArea(data: $data) {
      id
    }
  }
`;

export const createMnpTable = gql`
  mutation createMnpTable($data: CreateMnpTableInput!) {
    createMnpTable(data: $data) {
      id
    }
  }
`;

export const createMnpCategory = gql`
  mutation createMnpCategory($data: CreateMnpCategoryInput!) {
    createMnpCategory(data: $data) {
      id
    }
  }
`;

export const createMnpProduct = gql`
  mutation createMnpProduct($data: CreateMnpProductInput!) {
    createMnpProduct(data: $data) {
      id
    }
  }
`;

export const updateMnpUser = gql`
  mutation updateMnpUser($id: ID!, $data: UpdateMnpUserInput!) {
    updateMnpUser(id: $id, data: $data) {
      id
    }
  }
`;

export const updateMnpArea = gql`
  mutation updateMnpArea($id: ID!, $data: UpdateMnpAreaInput!) {
    updateMnpArea(id: $id, data: $data) {
      id
    }
  }
`;

export const updateMnpTable = gql`
  mutation updateMnpTable($id: ID!, $data: UpdateMnpTableInput!) {
    updateMnpTable(id: $id, data: $data) {
      id
    }
  }
`;

export const updateMnpCategory = gql`
  mutation updateMnpCategory($id: ID!, $data: UpdateMnpCategoryInput!) {
    updateMnpCategory(id: $id, data: $data) {
      id
    }
  }
`;

export const updateMnpProduct = gql`
  mutation updateMnpProduct($id: ID!, $data: UpdateMnpProductInput!) {
    updateMnpProduct(id: $id, data: $data) {
      id
    }
  }
`;

export const updateMnpPaymentAccount = gql`
  mutation updateMnpPaymentAccount(
    $id: ID!
    $data: UpdateMnpPaymentAccountInput!
  ) {
    updateMnpPaymentAccount(id: $id, data: $data) {
      id
    }
  }
`;

export const deleteOneMnpUser = gql`
  mutation deleteOneMnpUser($id: ID!) {
    deleteOneMnpUser(id: $id) {
      id
    }
  }
`;

export const deleteOneMnpArea = gql`
  mutation deleteOneMnpArea($id: ID!) {
    deleteOneMnpArea(id: $id) {
      id
    }
  }
`;

export const deleteOneMnpTable = gql`
  mutation deleteOneMnpTable($id: ID!) {
    deleteOneMnpTable(id: $id) {
      id
    }
  }
`;

export const deleteOneMnpCategory = gql`
  mutation deleteOneMnpCategory($id: ID!) {
    deleteOneMnpCategory(id: $id) {
      id
    }
  }
`;

export const deleteOneMnpProduct = gql`
  mutation deleteOneMnpProduct($id: ID!) {
    deleteOneMnpProduct(id: $id) {
      id
    }
  }
`;

export const deleteOneMnpPaymentAccount = gql`
  mutation deleteOneMnpPaymentAccount($id: ID!) {
    deleteOneMnpPaymentAccount(id: $id) {
      id
    }
  }
`;

export const loginMnpUser = gql`
  mutation loginMnpUser(
    $username: String!
    $password: String
    $option: String
  ) {
    loginMnpUser(username: $username, password: $password, option: $option) {
      token
      user {
        address
        createdAt
        email
        id
        key
        name
        partnerId
        phone
        role
        staffIds
        updatedAt
        partner {
          workPlaceId
          email
          address
          partnerTypes
          role
        }
      }
    }
  }
`;

export const mnpBookingTable = gql`
  mutation mnpBookingTable(
    $mnpTableId: ID!
    $bookingTime: DateTime!
    $guestCount: Int!
  ) {
    mnpBookingTable(
      mnpTableId: $mnpTableId
      bookingTime: $bookingTime
      guestCount: $guestCount
    ) {
      id
    }
  }
`;

export const mnpOrderProducts = gql`
  mutation mnpOrderProducts(
    $mnpTableId: ID
    $orders: [MnpOrderProductInput]!
    $paymentMethod: String
  ) {
    mnpOrderProducts(
      mnpTableId: $mnpTableId
      orders: $orders
      paymentMethod: $paymentMethod
    ) {
      id
    }
  }
`;

export const mnpTableCheckout = gql`
  mutation mnpTableCheckout($mnpTableId: ID, $paymentMethod: String!) {
    mnpTableCheckout(mnpTableId: $mnpTableId, paymentMethod: $paymentMethod) {
      id
      status
      name
      guestCount
    }
  }
`;

export const addPaymentAccount = gql`
  mutation addPaymentAccount(
    $bankId: ID!
    $bankName: String!
    $bankNo: String!
  ) {
    addPaymentAccount(bankId: $bankId, bankName: $bankName, bankNo: $bankNo) {
      id
      bankName
      bankNo
    }
  }
`;

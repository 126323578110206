// **
import React, { useState } from "react";
import { toast } from "react-hot-toast";

import FormNotification from "../notification-form";
import { createNewNotification } from "modules/ego-system/notification/stores";
import "./index.scss";
import { Card } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_ROUTER } from "router/ego-admin-routes/notification";
import PageWrapper from "components/containers/page/page-wrapper";

const NotificationFormSideBar = ({
  open,
  toggleSideBar,
  addSuccess,
  ...props
}) => {
  // ** state
  const [loading, setLoading] = useState(false);
  const [dataCkeditor, setDataCkeditor] = useState("<p>Ego notification</p>");

  const navigate = useNavigate();

  /**
   * @param {Object} NotificationInfo
   */
  const handleAddNewNotification = async (NotificationInfo) => {
    const customData = {
      ...NotificationInfo,
      content: dataCkeditor || "<p>Ego notification</p>",
    };

    try {
      setLoading(true);
      await createNewNotification(customData);
      toast.success("Thêm mới thông báo thành công!");
      navigate(NOTIFICATION_ROUTER.NOTICE);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title="Thêm khuyến mãi"
      breadcrumbs={[{ title: "Thêm khuyến mãi" }]}
      isGlobal
    >
      <Card className="add-notification-ego">
        <FormNotification
          loading={loading}
          toggleSideBar={toggleSideBar}
          addNotification={handleAddNewNotification}
          setDataCkeditor={setDataCkeditor}
          dataCkeditor={dataCkeditor}
        />
      </Card>
    </PageWrapper>
  );
};

export default NotificationFormSideBar;

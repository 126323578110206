import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const userAction = {
  getAllUser: gql`
    query getAllUser(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getAllUser(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
        fromDate: $fromDate
        toDate: $toDate
      ) {
        data {
          id
          name
          myCarIds
          username
          phone
          email
          password
          isBlock
          key
          role
          identityCard
          avatar
          address
          signInProvider
          isActived
          createdAt
          updatedAt
          driverLicenseInfos {
            id
            driverLicense
            driverLicenseType {
              id
              name
              type
            }
            type
          }
          trips {
            id
            code
            couponIds
            coupons {
              code
              discountCondition
              discountRate
              expireDate
              effectiveDate
              id
              maxDiscount
              quantity
              status
              title
              type
            }
            discountAmount
            distance
            dispatch {
              driver {
                name
                id
              }
            }
            dispatchId
            driverId
            finalCost
            initialCost
            startAddress
            endAddress
            status
            travelTime
            userId
            waitTime
            workPlace {
              id
              name
            }
            workPlaceId
          }
          workPlace {
            id
            name
          }
          workPlaceId
          location

          car {
            id
            carCode
            images
            driverId
            status
            category
            color
            carCompany
            tranportationType
            transmisstionType
            carType {
              category
              id
              name
            }
            fuelType
            licensePlateNumber
            manufacturingYear
            mortgage
            createdAt
            updatedAt
          }

          carType {
            category
            id
            name
          }

          carTypeId

          wallet {
            balance
          }
          walletId
          pointReward
          rank

          partnerTypes
          transportationTypes
          status
          driverLicenseTypes
          service
        }
        total
        getStatUser
        getStatManager
      }
    }
  `,

  getAllRewardHistory: gql`
    query getAllRewardHistory(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
    ) {
      getAllRewardHistory(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          activity
          type
          coupon {
            code
          }
          point

          expiredDate
          createdAt
        }

        total
      }
    }
  `,

  getOneUser: gql`
    query getOneUser($id: ID!) {
      getOneUser(id: $id) {
        id
        name
        username
        phone
        email
        password
        isBlock
        key
        role
        identityCard
        avatar
        address
        businessRules
        partnerTypes
        signInProvider
        isActived
        createdAt
        updatedAt
        transportationTypes
        location
        trips {
          id
          code
          couponIds
          createdAt
          coupons {
            code
            discountCondition
            discountRate
            expireDate
            effectiveDate
            id
            maxDiscount
            quantity
            status
            title
            type
          }
          discountAmount
          distance
          dispatch {
            driver {
              name
              id
            }
          }
          dispatchId
          driverId
          finalCost
          initialCost
          startAddress
          startFullAddress
          endAddress
          status
          travelTime
          userId
          waitTime
          workPlace {
            id
            name
          }
          workPlaceId
        }
        workPlace {
          id
          name
          landMarkIds
          landMarks {
            address
            id
            images
            location
            name
            description
          }
        }
        workPlaceIds
        workPlaces {
          id
          name
        }

        carTypes {
          id
          name
        }

        carTypeIds
        workPlaceId

        car {
          id
          carCode
          images
          driverId
          status
          category
          color
          carCompany
          tranportationType
          transmisstionType
          carType {
            category
            id
            name
          }
          fuelType
          licensePlateNumber
          manufacturingYear
          mortgage
          createdAt
          updatedAt
        }

        carType {
          id
          businessTypes
          category
          name
          type
        }
        walletId
        point
        rank
        pointReward
        wallet {
          balance
        }
      }
    }
  `,

  getAllBooking: gql`
    query getAllBooking(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getAllBooking(
        q: { limit: $limit, page: $page, filter: $filter, order: $order }
        fromDate: $fromDate
        toDate: $toDate
      ) {
        data {
          id
          startPoint
          endPoint
          startAddress
          endAddress
          couponIds
          status
          paymentMethod
          code
          driverId
          distance
          waitTime
          travelTime
          initialCost
          discountAmount
          finalCost
          userId
          driverPickupTime
          driverDropOffTime
          createdAt
          updatedAt
          distance
          workPlace {
            id
            name
          }
          workPlaceId
          user {
            name
            username
          }
          driver {
            name
            phone
            car {
              carCode
            }
          }
          nameClient
        }
        getStatBooking
        getStatBookingOfClient
        total
      }
    }
  `,

  createUser: gql`
    mutation createUser(
      $name: String!
      $email: String
      $phoneNumber: String!
      $identityCard: String!
      $address: String!
      $avatar: String!
    ) {
      createUser(
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        identityCard: $identityCard
        address: $address
        avatar: $avatar
      ) {
        id
      }
    }
  `,

  deleteOneUser: gql`
    mutation deleteOneUser($id: ID!) {
      deleteOneUser(id: $id) {
        id
        phone
      }
    }
  `,

  updateUser: gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
      updateUser(id: $id, data: $data) {
        id
        phone
      }
    }
  `,

  getStringeeToken: gql`
    query getStringeeToken($userId: ID!) {
      getStringeeToken(userId: $userId)
    }
  `,

  depositToWalletForAdmin: gql`
    mutation depositToWalletForAdmin($walletId: ID!, $balance: Float!) {
      depositToWalletForAdmin(walletId: $walletId, balance: $balance)
    }
  `,

  withdrawToWalletForAdmin: gql`
    mutation withdrawToWalletForAdmin($walletId: ID!, $balance: Float!) {
      withdrawToWalletForAdmin(walletId: $walletId, balance: $balance)
    }
  `,
};

export const userApi = {
  getAllUser: (payload) => {
    return query("getAllUser", userAction.getAllUser, payload);
  },

  getAllRewardHistory: (payload) => {
    return query(
      "getAllRewardHistory",
      userAction.getAllRewardHistory,
      payload
    );
  },

  getOneUser: (payload) => {
    return mutate("getOneUser", userAction.getOneUser, payload);
  },

  getAllBooking: (payload) => {
    return mutate("getAllBooking", userAction.getAllBooking, payload);
  },

  createUser: (payload) => {
    return mutate("createUser", userAction.createUser, payload);
  },

  deleteOneUser: (payload) => {
    return mutate("deleteOneUser", userAction.deleteOneUser, payload);
  },

  updateUser: (payload) => {
    return mutate("updateUser", userAction.updateUser, payload);
  },

  getStringeeToken: (payload) => {
    return query("getStringeeToken", userAction.getStringeeToken, payload);
  },

  depositToWalletForAdmin: (payload) => {
    return mutate(
      "depositToWalletForAdmin",
      userAction.depositToWalletForAdmin,
      payload
    );
  },

  withdrawToWalletForAdmin: (payload) => {
    return mutate(
      "withdrawToWalletForAdmin",
      userAction.withdrawToWalletForAdmin,
      payload
    );
  },
};

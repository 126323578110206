import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const carsPartnerAction = {
  getAllPartnerCar: gql`
    query getAllPartnerCar(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $fromDate: DateTime
      $toDate: DateTime
      $partnerId: ID!
    ) {
      getAllPartnerCar(
        q: {
          page: $page
          order: $order
          limit: $limit
          filter: $filter
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      ) {
        data {
          id
          carEgoId
          location
          carCode
          licensePlateNumber
          images
          carTypeId
          carCode
          partnerId
          countTrip
          isConnectedEgo
          driverId
          ownerId
          mortgage
          cleanFee
          garageId
          deodoriFee
          overlimitFee
          overtimeFee
          garage {
            id
            name
            address
          }
          owner {
            id
            name
            username
          }
          workPlace {
            id
            name
          }
          workPlaceId
          driver {
            id
            name
            address
            driverLicense
          }
          carType {
            id
            name
            type
          }
          partner {
            id
            phone
            email
            address
            avatar
          }
          createdAt
          updatedAt
          quantity
          isCompanyCar
          businessTypes
          address
          businessType
          carCompany
          category
          color
          description
          driverId
          fuelType
          location
          manufacturingYear
          price
          tagIds
          carFeatureIds
          transmisstionType
          deliveryKm
          deliveryCostPerKm
          freeDeliveryKm
          discountByWeek
          discountByMonth
          rentingQuantity
          service
        }
        total
      }
    }
  `,

  getAllCarOwner: gql`
    query getAllCarOwner(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
      $partnerId: ID!
    ) {
      getAllCarOwner(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      ) {
        data {
          avatar
          address
          createdAt
          id
          name
          phone
          status
          businessRules
          identityCard
          countCar
          workPlace {
            id
            name
          }
          workPlaceId
          workPlaces {
            id
            name
          }
          workPlaceIds
          note
        }
        getStatUser
        total
      }
    }
  `,

  getAllCarTypeWOPagingForPartner: gql`
    query getAllCarTypeWOPagingForPartner(
      $businessTypes: [String]
      $category: String
      $partnerId: ID!
    ) {
      getAllCarTypeWOPagingForPartner(
        businessTypes: $businessTypes
        category: $category
        partnerId: $partnerId
      ) {
        id
        name
      }
    }
  `,

  createPartnerCar: gql`
    mutation createPartnerCar($data: CreatePartnerCarInput!) {
      createPartnerCar(data: $data) {
        id
      }
    }
  `,

  updatePartnerCar: gql`
    mutation updatePartnerCar($id: ID!, $data: UpdatePartnerCarInput!) {
      updatePartnerCar(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOnePartnerCar: gql`
    mutation deleteOnePartnerCar($id: ID!) {
      deleteOnePartnerCar(id: $id) {
        id
      }
    }
  `,

  setCarForDriverPartner: gql`
    mutation setCarForDriverPartner($driverId: ID!, $carId: ID!) {
      setCarForDriverPartner(driverId: $driverId, carId: $carId)
    }
  `,

  disconnectDriverForCarPartner: gql`
    mutation unsetCarForDriverPartner($driverId: ID!) {
      unsetCarForDriverPartner(driverId: $driverId)
    }
  `,

  connectCarToEgo: gql`
    mutation connectCarToEgo($carId: ID!) {
      connectCarToEgo(carId: $carId)
    }
  `,

  disConnectCarToEgo: gql`
    mutation disconnectCarToEgo($carId: ID!) {
      disconnectCarToEgo(carId: $carId)
    }
  `,

  calcDistance: gql`
    mutation calcDistance($point1: [Float]!, $point2: [Float]!) {
      calcDistance(point1: $point1, point2: $point2)
    }
  `,

  getBillRentCarTripForPartner: gql`
    query getBillRentCarTripForPartner(
      $carId: ID!
      $pickupPoint: [Float]
      $startDate: DateTime!
      $endDate: DateTime!
      $isInvoice: Boolean!
    ) {
      getBillRentCarTripForPartner(
        carId: $carId
        pickupPoint: $pickupPoint
        startDate: $startDate
        endDate: $endDate
        isInvoice: $isInvoice
      )
    }
  `,

  getOnePartnerCar: gql`
    query getOnePartnerCar($id: ID!) {
      getOnePartnerCar(id: $id) {
        id
        carCode
        licensePlateNumber
        images
        carTypeId
        address
        rate
        price
        description
        location
        businessType
        businessTypes
        category
        carCompany
        color
        fuelType
        manufacturingYear
        status
        workPlaceId
        partnerId
        isConnectedEgo
        driverId
        isConnectedDriver
        tranportationType
        ownerId
        rentCarStatus
        isCompanyCar
        carFeatureIds
        tagIds
        deliveryKm
        deliveryCostPerKm
        freeDeliveryKm
        discountByWeek
        discountByMonth
        transmisstionType
        mortgage
        overtimeFee
        overlimitFee
        cleanFee
        deodoriFee
        driverFee
        countryId

        createdAt
        updatedAt
        driver {
          name
          id
        }
        partner {
          name
          id
        }
        workPlace {
          id
          name
        }
        carFeatures {
          id
          icon
          name
          createdAt
          updatedAt
        }
        tags {
          id
          name
          type
          createdAt
          updatedAt
        }
      }
    }
  `,
};

export const carPartnerApi = {
  getAllPartnerCar: (payload) => {
    return query(
      "getAllPartnerCar",
      carsPartnerAction.getAllPartnerCar,
      payload
    );
  },

  getAllCarOwner: (payload) => {
    return query("getAllCarOwner", carsPartnerAction.getAllCarOwner, payload);
  },

  getAllCarTypeWOPagingForPartner: (payload) => {
    return query(
      "getAllCarTypeWOPagingForPartner",
      carsPartnerAction.getAllCarTypeWOPagingForPartner,
      payload
    );
  },

  createPartnerCar: (payload) => {
    return mutate(
      "createPartnerCar",
      carsPartnerAction.createPartnerCar,
      payload
    );
  },

  updatePartnerCar: (payload) => {
    return mutate(
      "updatePartnerCar",
      carsPartnerAction.updatePartnerCar,
      payload
    );
  },

  deleteOnePartnerCar: (payload) => {
    return mutate(
      "deleteOnePartnerCar",
      carsPartnerAction.deleteOnePartnerCar,
      payload
    );
  },

  connectDriverToPartnerCar: (payload) => {
    return mutate(
      "setCarForDriverPartner",
      carsPartnerAction.setCarForDriverPartner,
      payload
    );
  },

  disconnectDriverToPartnerCar: (payload) => {
    return mutate(
      "unsetCarForDriverPartner",
      carsPartnerAction.disconnectDriverForCarPartner,
      payload
    );
  },

  connectEgoSystem: (payload) => {
    return mutate(
      "connectCarToEgo",
      carsPartnerAction.connectCarToEgo,
      payload
    );
  },

  disconnectEgoSystem: (payload) => {
    return mutate(
      "disConnectCarToEgo",
      carsPartnerAction.disConnectCarToEgo,
      payload
    );
  },

  calcDistance: (payload) => {
    return mutate("calcDistance", carsPartnerAction.calcDistance, payload);
  },

  getBillRentCarTripForPartner: (payload) => {
    return query(
      "getBillRentCarTripForPartner",
      carsPartnerAction.getBillRentCarTripForPartner,
      payload
    );
  },

  getOnePartnerCar: (id) => {
    return query("getOnePartnerCar", carsPartnerAction.getOnePartnerCar, id);
  },
};

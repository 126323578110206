import NotificationFormSideBar from "modules/ego-system/notification/components/add-notification-side-bar";
import { lazy } from "react";
import { KEYS } from "utility/permission/key-config";

const NoticeHistory = lazy(() => import("modules/ego-system/notification"));
const NoticeRedirect = lazy(
  () => import("modules/ego-system/notification-redirect")
);

export const NOTIFICATION_ROUTER = {
  NOTIFICATION: "/notification",
  NOTIFICATION_ADD: "/notification/add",
  NOTICE: "/notication-history",
  NOTICE_REDIRECT_MIDDLEWARE: "/redirect-notification/:notificationId",
};

const NotificationRoutes = [
  {
    path: NOTIFICATION_ROUTER.NOTICE_REDIRECT_MIDDLEWARE,
    element: <NoticeRedirect />,
    title: "Thông báo",
    meta: {
      permissionKey: KEYS.EGO.NOTICE_HISTORY,
    },
  },

  {
    path: NOTIFICATION_ROUTER.NOTICE,
    element: <NoticeHistory />,
    title: "Thông báo",
    meta: {
      permissionKey: KEYS.EGO.NOTICE_HISTORY,
    },
  },

  {
    path: NOTIFICATION_ROUTER.NOTIFICATION_ADD,
    element: <NotificationFormSideBar />,
    title: "Thêm thông báo",
    meta: {
      permissionKey: KEYS.EGO.NOTIFICATION_ADD,
    },
  },
];

export default NotificationRoutes;

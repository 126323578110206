import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cloneDeep from "clone-deep";
import toastNotice from "components/utils/toast-notice";
import localUtils from "utility/local-utils";
import LocalUtils from "utility/storage";
import { eposApi } from "../apis";

export const getSavedOrderDishesFromLocal = () => {
  try {
    const orderDishes = JSON.parse(localUtils.get("epos-saved-orderDishes"));

    return orderDishes || {};
  } catch (error) {
    return {};
  }
};

export const setSavedOrderDishesToLocal = (orderDishes) => {
  LocalUtils.set("epos-saved-orderDishes", JSON.stringify(orderDishes));
};

export const getOrderDishesFromLocal = () => {
  try {
    const orderDishes = JSON.parse(localUtils.get("epos-orderDishes"));

    return orderDishes || {};
  } catch (error) {
    return {};
  }
};

export const setOrderDishesToLocal = (orderDishes) => {
  LocalUtils.set("epos-orderDishes", JSON.stringify(orderDishes));
};

export const getAllMnpUser = createAsyncThunk(
  "/epos/getAllMnpUser",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpUser(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpArea = createAsyncThunk(
  "/epos/getAllMnpArea",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpArea(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpAreaWOPaging = createAsyncThunk(
  "/epos/getAllMnpAreaWOPaging",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpAreaWOPaging(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpTable = createAsyncThunk(
  "/epos/getAllMnpTable",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpTable(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpCategory = createAsyncThunk(
  "/epos/getAllMnpCategory",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpCategory(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpCategoryWOPaging = createAsyncThunk(
  "/epos/getAllMnpCategoryWOPaging",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpCategoryWOPaging(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllBankWOPaging = createAsyncThunk(
  "/epos/getAllBankWOPaging",
  async (params) => {
    try {
      const response = await eposApi.getAllBankWOPaging(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpProduct = createAsyncThunk(
  "/epos/getAllMnpProduct",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpProduct(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpOrder = createAsyncThunk(
  "/epos/getAllMnpOrder",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpOrder(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpOrderManager = createAsyncThunk(
  "/epos/getAllMnpOrderManager",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpOrderForAdmin(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const getAllMnpPaymentAccount = createAsyncThunk(
  "/epos/getAllMnpPaymentAccount",
  async (params) => {
    try {
      const response = await eposApi.getAllMnpPaymentAccount(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error.message,
      });
    }
  }
);

export const eposSlice = createSlice({
  name: "epos",
  initialState: {
    users: {
      records: [],
      total: 0,
    },

    areas: {
      records: [],
      total: 0,
    },

    orders: {
      records: [],
      total: 0,
    },

    areasWoPaging: [],
    categoriesWoPaging: [],
    ordersWoPaging: [],
    banksWoPaging: [],

    tables: {
      records: [],
      total: 0,
    },

    categories: {
      records: [],
      total: 0,
    },

    products: {
      records: [],
      total: 0,
    },

    banks: {
      records: [],
      total: 0,
    },

    orderDishes: getOrderDishesFromLocal(),
    savedOrder: getSavedOrderDishesFromLocal(),
  },

  reducers: {
    setUsers: (state, { payload }) => {
      state.users = payload;
    },

    addDishToCart: (state, { payload }) => {
      const tableId = payload.tableId;

      if (!state.orderDishes?.[tableId]) {
        state.orderDishes[tableId] = [{ ...payload.dish, quantity: 1 }];
        return;
      }

      state.orderDishes[tableId].push({ ...payload.dish, quantity: 1 });

      setOrderDishesToLocal(state.orderDishes);
    },

    updateDish: (state, { payload }) => {
      const tableId = payload.tableId;

      if (!tableId) {
        const orderDishes = state.orderDishes[tableId];
        const dishIndex = orderDishes.findIndex(
          (dish) => dish.id === payload.dishId
        );
        // state.orderDishes[tableId] = [{ ...payload.dish, quantity: 1 }];
        state.orderDishes[tableId].splice(dishIndex, 1, payload.dish);
        setOrderDishesToLocal(state.orderDishes);

        return;
      }

      const orderDishes = state.orderDishes[tableId];
      const dishIndex = orderDishes.findIndex(
        (dish) => dish.id === payload.dishId
      );
      state.orderDishes[tableId].splice(dishIndex, 1, payload.dish);

      setOrderDishesToLocal(state.orderDishes);
    },

    deleteDish: (state, { payload }) => {
      const tableId = payload.tableId;

      if (!tableId) {
        const orderDishes = state.orderDishes[tableId];
        const dishIndex = orderDishes.findIndex(
          (dish) => dish.id === payload.dishId
        );
        state.orderDishes?.[tableId].splice(dishIndex, 1);

        setOrderDishesToLocal(state.orderDishes);
        return;
      }

      const orderDishes = state.orderDishes[tableId];
      const dishIndex = orderDishes.findIndex(
        (dish) => dish.id === payload.dishId
      );
      state.orderDishes?.[tableId].splice(dishIndex, 1);

      setOrderDishesToLocal(state.orderDishes);
    },

    deleteTable: (state, { payload }) => {
      const tableId = payload.tableId;

      const arrayOrder = Object.entries(state.orderDishes);

      const tableIndex = arrayOrder.findIndex((table) => {
        return table?.[0] === tableId;
      });
      arrayOrder.splice(tableIndex, 1);

      const result = arrayOrder.reduce((acc, [key, value]) => {
        acc[key] = [...value];
        return acc;
      }, {});

      state.orderDishes = result;

      setOrderDishesToLocal(result);
    },

    saveOrder: (state, { payload }) => {
      const tableId = payload.tableId;

      if (!state.savedOrder?.[tableId]) {
        state.savedOrder[tableId] = state.orderDishes[tableId] || [];
        state.orderDishes[tableId] = [];
        return;
      }

      const cloneSavedDishes = cloneDeep(state.savedOrder[tableId]);
      state.savedOrder[tableId] = [
        ...cloneSavedDishes,
        ...(state.orderDishes[tableId] || []),
      ];

      state.orderDishes[tableId] = [];
    },

    clearOrderDishes: (state, { payload }) => {
      const tableId = payload.tableId;
      state.orderDishes[tableId] = [];
    },

    clearSaveOrder: (state, { payload }) => {
      const tableId = payload.tableId;
      state.savedOrder[tableId] = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllMnpUser.fulfilled, (state, { payload }) => {
      state.users.records = payload.data;
      state.users.total = payload.total;
    });

    builder.addCase(getAllMnpArea.fulfilled, (state, { payload }) => {
      state.areas.records = payload.data;
      state.areas.total = payload.total;
    });

    builder.addCase(getAllMnpAreaWOPaging.fulfilled, (state, { payload }) => {
      state.areasWoPaging = payload;
    });

    builder.addCase(getAllMnpTable.fulfilled, (state, { payload }) => {
      state.tables.records = payload.data;
      state.tables.total = payload.total;
    });

    builder.addCase(getAllMnpCategory.fulfilled, (state, { payload }) => {
      state.categories.records = payload.data;
      state.categories.total = payload.total;
    });

    builder.addCase(
      getAllMnpCategoryWOPaging.fulfilled,
      (state, { payload }) => {
        state.categoriesWoPaging = payload;
      }
    );

    builder.addCase(getAllBankWOPaging.fulfilled, (state, { payload }) => {
      state.banksWoPaging = payload;
    });

    builder.addCase(getAllMnpProduct.fulfilled, (state, { payload }) => {
      state.products.records = payload.data;
      state.products.total = payload.total;
    });

    builder.addCase(getAllMnpOrder.fulfilled, (state, { payload }) => {
      const customData = payload?.data?.map((item) => {
        return {
          id: item?.id,
          name: item?.mnpProduct?.name,
          price: item?.mnpProduct?.price,
          quantity: item?.count,
        };
      });
      state.ordersWoPaging = customData;
    });

    builder.addCase(getAllMnpOrderManager.fulfilled, (state, { payload }) => {
      state.orders.records = payload.data;
      state.orders.total = payload.total;
    });

    builder.addCase(getAllMnpPaymentAccount.fulfilled, (state, { payload }) => {
      state.banks.records = payload.data;
      state.banks.total = payload.total;
    });
  },
});

export const {
  setUsers,
  addDishToCart,
  updateDish,
  deleteDish,
  deleteTable,
  saveOrder,
  clearOrderDishes,
  clearSaveOrder,
} = eposSlice.actions;

export default eposSlice.reducer;

import Quill from "quill";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import withKeyboardDetectFocusInput from "utility/hocs/with-keyboard-detect";
import ModalSystem from "../modal-system";
import EditorUploadForm from "./components/upload-form";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [
    "link",
    "image",
    // 'video',
    // 'formula'
  ],

  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],

  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],

  ["clean"],
];

// Editor is an uncontrolled React component
const Editor = ({
  value,
  readOnly,
  defaultValue,
  onTextChange,
  onSelectionChange,
  customOption = {},
  onChange,
  focusIn,
  focusOut,
}) => {
  const ref = useRef();
  const containerRef = useRef(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onTextChange);
  const onSelectionChangeRef = useRef(onSelectionChange);

  const [cursorIndex, setCursorIndex] = useState(0);
  const [imageUpdateOpen, setImageUpdateOpen] = useState(false);

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    onSelectionChangeRef.current = onSelectionChange;
  });

  const closeImageUpload = () => {
    setCursorIndex(0);
    setImageUpdateOpen(false);
  };

  function imageHandler() {
    setCursorIndex(ref.current.selection.lastRange.index);
    setImageUpdateOpen(true);
  }

  const insertImage = (url) => {
    console.log(ref.current, url);
    ref.current.editor.insertEmbed(cursorIndex, "image", url);
    closeImageUpload();
  };

  useEffect(() => {
    ref.current?.enable(!readOnly);
  }, [ref, readOnly]);

  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = container.appendChild(
      container.ownerDocument.createElement("div")
    );

    const quill = new Quill(editorContainer, {
      placeholder: "Nội dung văn bản...",
      theme: "snow",
      modules: {
        history: {
          // Enable with custom configurations
          delay: 2500,
          userOnly: true,
        },

        toolbar: {
          container: toolbarOptions,
          handlers: {
            image: imageHandler,
          },
        },
      },

      ...customOption,
    });

    ref.current = quill;

    if (defaultValueRef.current) {
      quill.setContents(defaultValueRef.current);
    }

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      onTextChangeRef.current?.(...args);
      console.log("at here", quill.root.innerHTML, onchange);
      onChange?.(quill.root.innerHTML);
    });

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      onSelectionChangeRef.current?.(...args);
      onChange?.(quill.root.innerHTML);
    });

    return () => {
      ref.current = null;
      container.innerHTML = "";
    };
  }, [ref]);

  useEffect(() => {
    if (ref) {
      ref.current.root.innerHTML = value || "";
    }
  }, [ref]);

  return (
    <>
      <div onFocus={focusIn} onBlur={focusOut} ref={containerRef}></div>
      <ModalSystem
        isOpen={imageUpdateOpen}
        onClose={() => setImageUpdateOpen(false)}
      >
        <EditorUploadForm onSubmit={insertImage} onClose={closeImageUpload} />
      </ModalSystem>
    </>
  );
};

Editor.displayName = "Editor";

export default withKeyboardDetectFocusInput(Editor);

import { mutate, query } from "configs/http-config";
import {
  getAllMnpUser,
  getAllMnpArea,
  getAllMnpAreaWOPaging,
  getAllMnpTable,
  getAllMnpCategory,
  getAllMnpCategoryWOPaging,
  getAllBankWOPaging,
  getAllMnpProduct,
  getAllMnpOrder,
  getAllMnpOrderForAdmin,
  getAllMnpPaymentAccount,
  createMnpUser,
  createMnpArea,
  createMnpTable,
  createMnpCategory,
  createMnpProduct,
  updateMnpUser,
  updateMnpArea,
  updateMnpTable,
  updateMnpCategory,
  updateMnpProduct,
  updateMnpPaymentAccount,
  deleteOneMnpUser,
  deleteOneMnpArea,
  deleteOneMnpTable,
  deleteOneMnpCategory,
  deleteOneMnpProduct,
  deleteOneMnpPaymentAccount,
  loginMnpUser,
  mnpBookingTable,
  mnpOrderProducts,
  mnpTableCheckout,
  addPaymentAccount,
  getOneMnpPaymentAccount,
} from "./schema";

export const eposApi = {
  getAllMnpUser: (params) => {
    return query("getAllMnpUser", getAllMnpUser, {
      q: params,
    });
  },

  getAllMnpArea: (params) => {
    return query("getAllMnpArea", getAllMnpArea, {
      q: params,
    });
  },

  getAllMnpAreaWOPaging: () => {
    return query("getAllMnpAreaWOPaging", getAllMnpAreaWOPaging);
  },

  getAllMnpTable: (params) => {
    return query("getAllMnpTable", getAllMnpTable, {
      q: params,
    });
  },

  getAllMnpCategory: (params) => {
    return query("getAllMnpCategory", getAllMnpCategory, {
      q: params,
    });
  },

  getAllMnpCategoryWOPaging: () => {
    return query("getAllMnpCategoryWOPaging", getAllMnpCategoryWOPaging);
  },

  getAllBankWOPaging: () => {
    return query("getAllBankWOPaging", getAllBankWOPaging);
  },

  getAllMnpProduct: (params) => {
    return query("getAllMnpProduct", getAllMnpProduct, {
      q: params,
    });
  },

  getAllMnpOrder: (params) => {
    return query("getAllMnpOrder", getAllMnpOrder, {
      q: params,
    });
  },

  getAllMnpOrderForAdmin: (params) => {
    return query("getAllMnpOrderForAdmin", getAllMnpOrderForAdmin, {
      q: params,
    });
  },

  getAllMnpPaymentAccount: (params) => {
    return query("getAllMnpPaymentAccount", getAllMnpPaymentAccount, {
      q: params,
    });
  },

  getOneMnpPaymentAccount: (params) => {
    return query("getOneMnpPaymentAccount", getOneMnpPaymentAccount, params);
  },

  createMnpUser: (payload) => {
    return mutate("createMnpUser", createMnpUser, payload);
  },

  createMnpArea: (payload) => {
    return mutate("createMnpArea", createMnpArea, payload);
  },

  createMnpTable: (payload) => {
    return mutate("createMnpTable", createMnpTable, payload);
  },

  createMnpCategory: (payload) => {
    return mutate("createMnpCategory", createMnpCategory, payload);
  },

  createMnpProduct: (payload) => {
    return mutate("createMnpProduct", createMnpProduct, payload);
  },

  updateMnpUser: (payload) => {
    return mutate("updateMnpUser", updateMnpUser, payload);
  },

  updateMnpArea: (payload) => {
    return mutate("updateMnpArea", updateMnpArea, payload);
  },

  updateMnpTable: (payload) => {
    return mutate("updateMnpTable", updateMnpTable, payload);
  },

  updateMnpCategory: (payload) => {
    return mutate("updateMnpCategory", updateMnpCategory, payload);
  },

  updateMnpProduct: (payload) => {
    return mutate("updateMnpProduct", updateMnpProduct, payload);
  },

  updateMnpPaymentAccount: (payload) => {
    return mutate("updateMnpPaymentAccount", updateMnpPaymentAccount, payload);
  },

  deleteOneMnpUser: (payload) => {
    return mutate("deleteOneMnpUser", deleteOneMnpUser, payload);
  },

  deleteOneMnpArea: (payload) => {
    return mutate("deleteOneMnpArea", deleteOneMnpArea, payload);
  },

  deleteOneMnpTable: (payload) => {
    return mutate("deleteOneMnpTable", deleteOneMnpTable, payload);
  },

  deleteOneMnpCategory: (payload) => {
    return mutate("deleteOneMnpCategory", deleteOneMnpCategory, payload);
  },

  deleteOneMnpProduct: (payload) => {
    return mutate("deleteOneMnpProduct", deleteOneMnpProduct, payload);
  },

  deleteOneMnpPaymentAccount: (payload) => {
    return mutate(
      "deleteOneMnpPaymentAccount",
      deleteOneMnpPaymentAccount,
      payload
    );
  },

  loginMnpUser: (payload) => {
    return mutate("loginMnpUser", loginMnpUser, payload);
  },

  mnpBookingTable: (payload) => {
    return mutate("mnpBookingTable", mnpBookingTable, payload);
  },

  mnpOrderProducts: (payload) => {
    return mutate("mnpOrderProducts", mnpOrderProducts, payload);
  },

  mnpTableCheckout: (payload) => {
    return mutate("mnpTableCheckout", mnpTableCheckout, payload);
  },

  addPaymentAccount: (payload) => {
    return mutate("addPaymentAccount", addPaymentAccount, payload);
  },
};

// ** React Imports
import { lazy } from "react";

const Login = lazy(() => import("modules/authentication/components/login"));
const LoginEposApp = lazy(() => import("modules/ePos/page/login-epos"));

const ForgotPassword = lazy(
  () => import("modules/authentication/components/forgot-password")
);

export const AUTH_ROUTES = {
  LOGIN: "/login",
  LOGIN_EPOS: "/login/epos",
  FORGOT_PASSWORD: "/forgot-password",
};

const AuthenticationRoutes = [
  {
    path: AUTH_ROUTES.LOGIN,
    element: <Login />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },

  {
    path: AUTH_ROUTES.LOGIN_EPOS,
    element: <LoginEposApp />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },

  {
    path: AUTH_ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
];

export default AuthenticationRoutes;
